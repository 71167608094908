<template>
	<com-mon>
		<div v-loading="pageloading">
			<div class="box-card">
				<div style="font-size:14px">商家可录入智能回复知识库，客户咨询时将先根据录入的知识库自动回复客户，无法回复时再转到人工服务（注：客户的咨询信息命中关键词"人工"时，也将转到人工服务）。
					<el-popover class="box-card_txt" placement="bottom" trigger="hover">
						<img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/17839082246twice.png" style="width:200px" alt="">
						<el-button slot="reference" type="text">查看示例</el-button>
					</el-popover>
				</div>
			</div>
			<el-card style="margin-top:10px" shadow="never">
				<div class="theFlex">
					<div class="search-box">
						<div style="font-size:14px;line-height:37px;font-weight: bold;color: #606266;">关键字:</div>
						<el-input v-model="keywords" placeholder="请输入问题查询"
							style="width:250px;margin:0px 10px 5px 10px;">
						</el-input>
						<div>
							<el-button type="primary" style="margin:0px 10px 5px 10px;width:90px" @click="query">查询
							</el-button>
						</div>
						<div>
						<buttonPermissions datas="smartreplyPush">
							<el-button type="primary" style="margin:0px 10px 5px 10px" @click="tagListAdd">添加问答</el-button>
						</buttonPermissions>
						</div>
						<div>
						</div>
					</div>
				</div>
			</el-card>
			<div style="margin-top:10px" class="flexRow">
				<el-card shadow="never" style="width:450px;height:730px;float:left;" v-loading="leftLoading">
					<div style="display: flex;justify-content:space-between;align-items:center;height:30px">
						<div style="font-size:14px;coler:#606266;font-weight: bold;">分组</div>
						<buttonPermissions datas="smartreplyPush">
							<el-button @click="addGroup" type="text" style="margin-right:20px;font-size:14px;color: #409EEF;cursor: pointer;">+添加分组</el-button>
						</buttonPermissions>
					</div>
					<!-- <div style="font-size:14px;text-align:center;color:rgb(150,150,150)">
						暂无数据
					</div> -->
					
					<div v-if="leftGroupList.length<=0" style="width:100%;font-size:14px;height:40px;line-height:40px;padding:0px 3px"
						@click="getAllData" :class="{tagNameBgc:changeLeftTag==-1||mouseoverNum==-1,chooseTag:changeLeftTag==-1}">全部分组</div>
					<div v-else	style="width:100%;height:690px;overflow:hidden;overflow-y:auto;padding:10px 0px 30px 0px">
						<div style="width:100%;font-size:14px;height:40px;line-height:40px;padding:0px 3px"
							@click="getAllData" :class="{tagNameBgc:changeLeftTag==-1||mouseoverNum==-1,chooseTag:changeLeftTag==-1}">全部分组</div>
						<div style="width:100%;padding:0px 3px" class="tag-item"
							:class="{tagNameBgc:changeLeftTag==i||mouseoverNum==i}" @mouseover="mouseover(i)"
							@mouseout="mouseout()" v-for="(v,i) in leftGroupList" :key="i">
							<div class="tag-item-left" @click="queryList(v,i)">
								<div class="name">
									<div class="fontHidden1" :class="{chooseTag:changeLeftTag==i}">{{v.SmartReplyGroupName}}</div>
								</div>
							</div>
							<div :class="{'tagNameBtn-Box':changeLeftTag!=i}" class="tag-item-right">
								<buttonPermissions datas="troubleEdit" style="padding:0px;margin:0px 10px 0px 0px;">
									<el-button type="text"	@click="tagListEdit(v)">编辑</el-button>
								</buttonPermissions>
								<buttonPermissions datas="troubleDelete" style="padding:0px;margin:0px 10px 0px 0px;">
									<el-button type="text"	style="color:rgb(245,108,108);"	@click="tagDetele(v)">删除</el-button>
								</buttonPermissions>
							</div>
						</div>
					</div>
				</el-card>
				<el-card shadow="never"
					style="flex:1;width:100%;height:730px;overflow:hidden;overflow-y:auto;margin-left:10px">
					<div style="font-size:14px;color: #666;margin-bottom: 5px;font-weight:bold" v-if="groupMsg.SmartReplyGroupName">{{groupMsg.SmartReplyGroupName}}：
					</div>
					<el-table :data="tablelist" v-loading="tableloading" style="margin-top:10px"
						@selection-change="handleSelectionChange" ref="multipleTableQuestion">
						<el-table-column type="selection" width="45"> </el-table-column>
						<el-table-column prop="Question" label="问题">
							<template slot-scope="scope">
								<div class="fontHidden1">
									{{scope.row.Question}}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="ReplyText" label="回复" width="400">
							<template slot-scope="scope">
								<div class ="flexRow" style="white-space:nowrap">
									<span class="fontHidden1" style="max-width:200px">{{scope.row.ReplyText}}</span> <span>{{scope.row.AttachmentTypeValue}}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="SmartReplyGroupName" label="分组">
							<template slot-scope="scope">
								<div>
									{{scope.row.SmartReplyGroupName}}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="" label="操作" width="100px">
							<template slot-scope="scope">
								<div>
									<buttonPermissions datas="troubleEdit">
										<el-button type="text" @click="edittable(scope.row)" style="margin-right: 5px">编辑</el-button>
									</buttonPermissions>
									<buttonPermissions datas="troubleDelete">
									<el-button type="text" style="color:rgb(245,108,108);margin-left:5px" @click="troubleDelete(scope.row)">删除</el-button>
									</buttonPermissions>
								</div>
							</template>
						</el-table-column>
					</el-table>
					<div style="margin-top:5px;text-align:right" v-if="total">
						<div style="font-size: 12px;color: #606266;float:left">
							<el-checkbox @change="tabCheckedChange" style="margin-left:15px" :value="checked">当前页全选</el-checkbox>
							<buttonPermissions datas="handsome">
								<el-button size="mini" style="margin-left:10px" @click="handsome">修改分组</el-button>
							</buttonPermissions>
							<buttonPermissions datas="troubleDelete">
								<el-button size="mini" @click="deleteList" style="margin-left:10px">删除</el-button>
							</buttonPermissions>
						</div>
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="currentPage" :page-sizes="[10, 20, 30, 40,50]" :page-size="sizepage"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
				</el-card>
			</div>
			<!-- 添加标签 -->
			<el-dialog title="添加标签" :visible.sync="addTagshow" v-loading="addTagLoading" width="500px"
				@closed="addTagClosed">
				<div style="max-height:500px;overflow-y:auto">
					<el-form :model="addruleForm" :rules="addrules" ref="addTagForm" style="margin-top:10px">
						<el-form-item :prop="'TagDetails.'+i+'.Name'" v-for="(v,i) in addruleForm.TagDetails" :key="i"
							:label="i==0?'标签名称:':''" label-width="100px" :rules="addrules.Name">
							<el-input v-model.trim="v.Name" :maxlength="maxlength" placeholder="请输入标签名称"
								@input="limit30" style="width:300px"></el-input>
							<i v-show="addruleForm.TagDetails.length > 1" class="el-icon-close"
								style="margin-left:15px;cursor:pointer;" @click="closeIconDelTag(i)"></i>
						</el-form-item>
					</el-form>
				</div>
				<el-button type="text" icon="el-icon-circle-plus-outline" style="margin-left:100px" @click="pushInTag">
					添加标签</el-button>
				<div style="text-align:right">
					<el-button @click="addTagshow = false">取消</el-button>
					<el-button type="primary" @click="saveAddTag('addTagForm')">保存</el-button>
				</div>
			</el-dialog>
			<!-- 编辑标签组 -->
			<el-dialog title="编辑分组" :visible.sync="editTagListshow" v-loading="editTagListLoading" width="500px"
				@closed="editTagListClosed">
				<el-form :model="editruleForm" :rules="editrules" ref="editTagListForm">
					<el-form-item prop="Edit" label="分组名称：" label-width="100px">
						<el-input v-model.trim="editruleForm.Edit" maxlength="12"
							style="width:300px" placeholder="请输入分组名称，最多12个字">
						</el-input>
					</el-form-item>
				</el-form>
				<div style="text-align:right">
					<el-button @click="editTagListshow = false">取消</el-button>
					<el-button type="primary" @click="saveEditTagList('editTagListForm')">保存</el-button>
				</div>
			</el-dialog>
			<!-- 添加标签组 -->
			<el-dialog title="添加标签组" :visible.sync="addTagListshow" v-loading="addTagListLoading" width="500px"
				@closed="addTagListClosed">
				<div style="max-height:500px;overflow-y:auto">
					<el-form :model="addListruleForm" :rules="addListrules" ref="addTagListForm">
						<el-form-item prop="Name" label="标签组名称:" label-width="100px">
							<el-input v-model.trim="addListruleForm.Name" placeholder="请输入标签组名称" :maxlength="maxlength"
								@input="limit30" style="width:300px"></el-input>
						</el-form-item>
						<el-form-item v-for="(v,i) in addListruleForm.TagDetails" :key="i"
							:prop="'TagDetails.'+i+'.Name'" :rules="addListrules.TagDetails" :label="i==0?'标签名称:':''"
							label-width="100px">
							<el-input v-model.trim="v.Name" :maxlength="maxlength" @input="limit30"
								placeholder="请输入标签名称" style="width:300px"></el-input>
							<i v-show="addListruleForm.TagDetails.length > 1" class="el-icon-close"
								style="margin-left:15px;cursor:pointer;" @click="closeIconDel(i)"></i>
						</el-form-item>
					</el-form>
				</div>
				<el-button type="text" icon="el-icon-circle-plus-outline" style="margin-left:100px" @click="pushTag">
					添加标签
				</el-button>
				<div style="text-align:right">
					<el-button @click="addTagListshow = false">取消</el-button>
					<el-button type="primary" @click="saveAddTagList('addTagListForm')">保存</el-button>
				</div>
			</el-dialog>

			<!-- 编辑表格内标签 -->
			<el-dialog title="编辑标签" :visible.sync="editTableshow" v-loading="editTableLoading" width="500px"
				@closed="editTableClosed">
				<el-form :model="editTableTag" :rules="editTableTagRules" ref="editTableForm">
					<el-form-item label="标签名称:" label-width="100px" prop="Name">
						<el-input v-model.trim="editTableTag.Name" :maxlength="maxlength" @input="limit30"
							style="width:300px" placeholder="请输入标签名称"></el-input>
					</el-form-item>
				</el-form>
				<div style="text-align:right">
					<el-button @click="editTableshow = false">取消</el-button>
					<el-button type="primary" @click="saveEditTableTag('editTableForm')">保存</el-button>
				</div>
			</el-dialog>
			<!-- 添加分组弹框 -->
			<el-dialog title="添加分组" :visible.sync="dialogFormVisible" v-loading="addTagListLoading" width="600px" @closed="editruleForm.Name = ''">
				<el-form :model="editruleForm" :rules="editTableTagRules" ref="ruleForm" label-width="100px">
					<el-form-item label="分组名称：" prop="Name">
						<el-input v-model="editruleForm.Name" placeholder="请输入分组名称，最多12个字" maxlength="12"></el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogFormVisible = false">取 消</el-button>
					<el-button type="primary" @click="groupSave">保 存</el-button>
				</span>
			</el-dialog>
			<!-- 批量修改分组弹框 -->
			<el-dialog class="edit-grade" title="批量修改分组" :visible.sync="editGradeDialog" width="600px"
				@closed="closededitGrade">
				<el-form :rules="ruleSelect">
					<el-form-item label="分组：" prop="editGrade">
						<el-select v-model="editGrade" placeholder="请选择分组" style="width:450px" @change="change">
							<el-option v-for="item in Idlist" :key="item.Id" :label="item.SmartReplyGroupName"
								:value="item.Id"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer" style="text-align:right;display:block;margin-top:-40px">
					<el-button @click="editGradeDialog=false">取 消</el-button>
					<el-button type="primary" @click="confirmChangeLevel">保 存</el-button>
				</span>
			</el-dialog>
		</div>
	</com-mon>
</template>
<script>
	import {
		smartReplyGroupList,
		smartReplyGroupSave,
		smartReplyGroupDelete,
		smartReplyGroupQAList,
		batchUpdateGroup,
		batchDelete
	} from "@/api/sv1.0.0.js"
	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
	import Commom from "@/views/customerServe/component/Common.vue" //引入组件
	export default {
		components: {
			"com-mon": Commom,
			buttonPermissions
		},
		data() {
			var IsEmptyList = (rule, value, callback) => {
				if (value == '') {
					callback(new Error("请输入分组名称"))
				} else {
					callback()
				}
			};
			var IsEmpty = (rule, value, callback) => {
				if (value == '') {
					callback(new Error("请输入分组名称"))
				} else {
					callback()
				}
			};
			var limitCharacter = (rule, value, callback) => {
				let len = 0
				for (let i = 0; i < value.length; i++) {
					var a = value.charAt(i);
					if (a.match(/[^\x00-\xff]/ig) != null) {
						//汉字占两个字符
						len += 2;
					} else {
						//其他占一个字符
						len += 1;
					}
				}
				if (len > 30) {
					callback(new Error('最长可输入30个字符'))
				} else {
					callback()
				}

			};
			return {
				editGrade: '',
				ruleSelect: {
					editGrade: [{
						required: true,
						message: '请选择分组',
						trigger: 'click'
					}]
				},
				multipleSelection: [],
				groupIdlist: '',
				editGradeDialog: '',
				dialogFormVisible: false,
				exportUrl: config.EXPORT_URL,
				pageloading: false,
				keywords: '',
				leftGroupList: [],
				leftLoading: false,
				tablelist: [],
				tableloading: false,
				currentPage: 1,
				sizepage: 20,
				total: null,
				addruleForm: { //添加小标签
					TagGroupId: 0,
					Name: '',
					TagDetailCount: 0,
					TagDetails: [{
						Name: '',
					}]
				},
				editTagListForm: {
					Edit: [{
						required: true,
						message: '请选择分组',
						trigger: 'click'
					}]
				},
				addrules: {
					Name: [{

						validator: IsEmpty
					}, {
						required: true,
						trigger: 'change',
						validator: limitCharacter
					}, ],
				},
				editruleForm: { //编辑标签组
					Name: '',
					Edit: '',
					TagDetailCount: 0,
					TagGroupId: 0,
				},
				editrules: {
					Name: [{
						required: true,
						trigger: 'blur',
						validator: IsEmptyList
					}, {
						required: true,
						trigger: 'change',
						validator: limitCharacter
					}, ],
					Edit: [{
						required: true,
						message: '请输入分组名称',
						trigger: 'click'
					}]
				},
				addListruleForm: { //添加标签组
					Name: '',
					TagDetails: [{
						Name: ''
					}, ],
				},
				addListrules: {
					Name: [{
						required: true,
						trigger: 'blur',
						validator: IsEmptyList
					}, {
						required: true,
						trigger: 'change',
						validator: limitCharacter
					}, ],
					TagDetails: [{
						required: true,
						trigger: 'blur',
						validator: IsEmpty
					}, {
						required: true,
						trigger: 'change',
						validator: limitCharacter
					}, ]
				},
				//添加标签
				addTagshow: false,
				addTagLoading: false,
				//编辑标
				editTagListshow: false,
				editTagListLoading: false,
				//添加标签组组组组组组组
				addTagListshow: false,
				addTagListLoading: false,

				currentId: 0, //当前的标签组Id

				editTableTag: {
					TagDetailId: '',
					Name: '',
				},
				editTableTagRules: {
					Name: [{
						required: true,
						trigger: 'blur',
						validator: IsEmpty
					}, {
						required: true,
						trigger: 'change',
						validator: limitCharacter
					}, ]
				},
				editTableshow: false,
				editGradeDialog: false,
				editTableLoading: false,
				maxlength: 12,
				changeLeftTag: -1,
				mouseoverNum: -1,
				TagGroup: {},
				idEdit: "",
				groupId: '',
				rowId: '',
				Idlist: [],

			}
		},
		computed: {
			checked() {
				if (this.tablelist && this.tablelist.length) {
					return this.tablelist.every((v) => {
						return this.multipleSelection.some((x) => {
							return x.Id == v.Id
						})
					})
				} else {
					return false
				}
			},
			groupMsg(){
				if(this.changeLeftTag == -1){
					return {SmartReplyGroupName:'全部分组'}
				}else{
					return this.leftGroupList[this.changeLeftTag]
				}
			},
		},
		created() {
			this.gettablelist()
			this.gettagList()
			this.tosynchronous()
			if (this.changeLeftTag == -1) {
				this.gettablelist()
			}
		},
		methods: {
			tagListAdd(){
				this.$router.push("/customerServe/WxCustomerServe/smartReply/ConfigurationSmart")
			},
			closededitGrade() {
				this.editGrade = null
				this.editGradeDialog = false
			},
			change(val) {
				this.SmartReplyGroupId = val
				console.log(this.SmartReplyGroupId)
			},
			//批量修改分组保存
			confirmChangeLevel() {
				if (!this.editGrade) {
					this.$message.error('请选择分组')
					return
				} else {
					this.editGradeDialog = false
					this.batchUpdateGroup()
				}
			},
			//智能回复分组删除
			troubleDelete(row) {
				this.rowId = row.Id
				this.$confirm('是否确定删除该智能回复？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.batchDelete()
					//this.gettablelist()
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			//智能分组删除
			async batchDelete() {
				try {
					let data = {
						Ids: [this.rowId]
					}
					let res = await batchDelete(data)
					if (res.IsSuccess) {
						this.changeLeftTag = -1
						this.$message.success({
							message: "删除成功"
						})
						this.gettagList()
					}
				} finally {

					// this.smartReplyGroupQAList()
				}
			},
			//批量修改分组
			handsome() {
				if (this.multipleSelection.length < 1) {
					this.$message({
						type: "error",
						message: "请先选择智能回复数据",
					});
					return;
				} else {
					this.editGradeDialog = true
				}
			},
			//批量分组删除
			async batchDeleteAll() {

				try {
					let data = {
						Ids: this.groupIdlist
					}
					let res = await batchDelete(data)
					if (res.IsSuccess) {
						this.changeLeftTag = -1
						this.$message.success({
							message: "删除成功"
						})
						this.gettagList()
					}
				} finally {
					//  this.smartReplyGroupQAList()
				}
			},
			//批量删除
			deleteList() {
				if (this.multipleSelection.length < 1) {
					this.$message({
						type: "error",
						message: "请先选择智能回复数据",
					});
				} else {
					this.$confirm('是否确定批量删除选中的智能回复?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.batchDeleteAll()
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						});
					});
				}
			},
			//批量修改分组接口
			async batchUpdateGroup() {
				try {
					let data = {
						Ids: this.groupIdlist,
						SmartReplyGroupId: this.SmartReplyGroupId //分组ID
					}
					let res = await batchUpdateGroup(data)
					if (res.IsSuccess) {
						this.$message.success(({
							message: "分组修改成功"
						}))
						
						this.changeLeftTag=-1
						this.gettablelist()
					}
				} finally {}
			},
			handleSelectionChange(val) {
				this.groupIdlist = val.map((item) => {
					return item.Id
				})
				this.multipleSelection = val;
			},
			tabCheckedChange() {
				this.$refs["multipleTableQuestion"].toggleAllSelection();
			},
			//添加分组
			addGroup() {
				this.dialogFormVisible = true
			},
			//添加分组保存
			groupSave() {
				this.$refs.ruleForm.validate((val)=>{
					if(val){
						this.smartReplyGroupSave()
					}
				})
			},
			//添加分组保存
			async smartReplyGroupSave() {
				this.addTagListLoading = true
				try {
					let data = {
						Id: 0,
						SmartReplyGroupName: this.editruleForm.Name
					}
					let result = await smartReplyGroupSave(data)
					if (result.IsSuccess) {
						this.changeLeftTag = -1
						this.$message.success('操作成功')
						this.editruleForm.Name = ""
						this.dialogFormVisible = false
						this.gettagList()
					}
				} finally {
					this.addTagListLoading = false
				}
			},
			mouseout() {
				this.mouseoverNum = null
			},
			mouseover(e) {
				this.mouseoverNum = e
			},
			//已打标签人数跳转客户查询
			tocuseomerSearch(e) {
				this.$router.push({
					path: '/Customer/CustomerSearch',
					query: {
						customerTagId: e.TagDetailId
					}
				})
			},
			//标签匹配规则跳转自动打标签
			toautoAddTag(e) {
				this.$router.push({
					path: '/Customer/autoAddTag',
					query: {
						TagDetailId: e.TagDetailId
					}
				})
			},
			//自动打标签
			jump() {
				this.$router.push({
					path: '/Customer/autoAddTag'
				})
			},
			limit30(e) {
				let len = 0
				let strnum = 0
				this.maxlength = 30 //每次初始化一下输入最大值  不然在输入值满了之后 删除一位就无法再输入
				for (let i = 0; i < e.length; i++) {
					var a = e.charAt(i);
					if (a.match(/[^\x00-\xff]/ig) != null) {
						//汉字占两个字符
						len += 2;
					} else {
						//其他占一个字符
						len += 1;
					}
					if (len < 30) {
						strnum += 1
					} else {
						this.maxlength = strnum
					}
				}
			},
			//编辑表格中的标签  确认提交
			saveEditTableTag(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.totagedit()
					} else {
						return false;
					}
				});
			},
			//编辑表格内的标签
			//智能回复分组编辑
			//智能回复分组编辑
			edittable(row) {
				console.log(row.Id)
				this.Id = row.Id
				this.$router.push({
					path: '/customerServe/WxCustomerServe/smartReply/ConfigurationSmart',
					query: {
						editId: this.Id
					}
				})
			},
			editTableClosed() {
				this.editTableTag = {
					TagDetailId: '',
					Name: '',
				}
				this.$refs.editTableForm.resetFields()
			},
			tagInput() {},
			// //添加标签  确认提交
			saveAddTag(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.totagadd()
					} else {
						setTimeout(() => {
							var isError = document.getElementsByClassName("is-error")
							if (isError[0].querySelector('input')) {
								isError[0].querySelector('input').focus();
							}
						}, 1)
						return false;
					}
				});
			},
			//添加标签  删除标签
			closeIconDelTag(e) {
				this.addruleForm.TagDetails.splice(e, 1)
				this.$forceUpdate()
			},
			//添加标签内的添加标签
			pushInTag() {
				let data = {
					SmartReplyGroupName: ''
				}
				this.addruleForm.TagDetails.push(data)
				this.$forceUpdate()
			},
			//添加标签
			tagAdd(e) {
				this.addruleForm = Object.assign(this.addruleForm, e);
				let TagDetails = [{
					Name: '',
				}, ]
				this.addruleForm.TagDetails = TagDetails
				this.$forceUpdate()
				this.addTagshow = true
				console.log(e)

			},
			//编辑标签组
			tagListEdit(e) {
				console.log(e)
				this.idEdit = e.Id
				this.editruleForm.Edit = e.SmartReplyGroupName
				this.editTagListshow = true
			},
			//编辑标签组 确认提交
			saveEditTagList(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.togroupedit()
						this.editTagListshow = false
					} else {
						return false;
					}
				});
			},
			async togroupedit(e) {
				this.editTagListLoading = true
				try {
					let data = {
						Id: this.idEdit,
						SmartReplyGroupName: this.editruleForm.Edit
					}
					let result = await smartReplyGroupSave(data)
					if (result.IsSuccess) {
						this.changeLeftTag = -1
						this.$message.success('操作成功')
						this.gettagList()
					}
				} finally {
					this.editTagListLoading = false
					this.editTagListshow = false

				}
			},
			//关闭添加标签组回调
			addTagListClosed() {
				this.addListruleForm = {
					Name: '',
					TagDetails: [{
						Name: ''
					}, ],
				}
				this.$refs.addTagListForm.resetFields()
				this.$forceUpdate()
			},
			//关闭编辑标签
			editTagListClosed() {
				this.editruleForm = { //编辑标签组
					Name: '',
					Edit: '',
					TagDetailCount: 0,
					Id: 0,
				}
				this.$refs.editTagListForm.resetFields()
				this.$forceUpdate()
			},
			//关闭添加标签
			addTagClosed() {
				this.addruleForm = { //添加小标签
					TagGroupId: 0,
					Name: '',
					TagDetailCount: 0,
					TagDetails: [{
						Name: '',
					}, ]
				}
				this.$refs.addTagForm.resetFields()
				this.$forceUpdate()
			},
			//添加标签组 删除标签
			closeIconDel(e) {
				this.addListruleForm.TagDetails.splice(e, 1)
			},
			//添加标签组 添加标签
			pushTag() {
				let data = {
					Name: ''
				}
				this.addListruleForm.TagDetails.push(data)
				this.$forceUpdate()
			},
			//删除标签组
			tagDetele(e) {
				// console.log(e, 123)
				this.$confirm(`是否确认删除分组【${e.SmartReplyGroupName}】，和该分组下关联的智能回复？若你不想删除智能回复，可先修改分组。`, '提示', {
					confirmButtonText: '删除分组和智能回复',
					cancelButtonText: '先修改分组',
					type: 'warning'
				}).then(() => {
					this.togroupdel(e)
					this.gettagList()
				}).catch(() => {})
			},
			//删除标签组
			async togroupdel(e) {
				this.pageloading = true
				try {
					let Id = await e
					let data = {
						Id: Id.Id
					}
					let result = await smartReplyGroupDelete(data)
					if (result.IsSuccess) {
						this.changeLeftTag = -1
						this.$message.success('操作成功')
						this.gettagList()
					}
				} finally {
					this.pageloading = false
				}
			},
			//查询
			query() {
			  this.changeLeftTag = -1;
				this.currentPage = 1
				this.gettagList()
			},
			//点击更新相应的列表
			queryList(e, i) {
				if (e) {
					// this.groupMsg = e
					this.changeLeftTag = i
					this.currentId = e.Id
					this.currentPage = 1,
					this.sizepage = 20
					this.total = null
					this.gettablelist(e.Id)
        }
			},
			getAllData() {
				// this.groupMsg = {SmartReplyGroupName:'全部分组'}
				this.changeLeftTag = -1;
				this.currentId = null;
				this.currentPage = 1,
				this.sizepage = 20
				this.total = null
				this.gettablelist(null)
			},
			handleSizeChange(e) {
				 //this.currentPage = 1
				this.sizepage = e
				this.gettablelist(this.currentId)
			},
			handleCurrentChange(e) {
				this.currentPage = e
				this.gettablelist(this.currentId)
			},
			//同步
			async tosynchronous() {
				let that = this
				that.synchronous = {
					loading: true,
					tip: '同步中...'
				}
				setTimeout(function() {
					that.synchronous = {
						loading: false,
						tip: '同步企业微信'
					}
				}, 2000)

			},
			//获取分组数据
			async gettagList() {
				this.leftLoading = true
				try {
					let data = {
						Skip: 0,
						Take: 10000,
					}
					let result = await smartReplyGroupList(data)
					// console.log(result)
					if (result.IsSuccess) {
						this.leftGroupList = result.Result
						this.Idlist = result.Result
						this.gettablelist(null)
					}
				} finally {
					this.leftLoading = false
				}
			},
			//表格数据
			async gettablelist(id) {
				this.tableloading = true
				let data = {
					KeyWord: this.keywords,
					SmartReplyGroupId: id,
					Skip: (this.currentPage - 1) * this.sizepage,
					Take: this.sizepage,
				}
				let result = await smartReplyGroupQAList(data)
				if (result.IsSuccess) {
					this.TagGroup = this.leftGroupList[this.changeLeftTag] || {};
					this.tablelist = result.Result.Results
					this.total = result.Result.Total
				}
				this.tableloading = false
			},
		}
	};
</script>

<style lang='less' scoped>
	.box-card {
		// height: 37px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 12px;
		color: #606266;
		text-align: left;
		background: white;
		padding:5px 16px;

		.box-card_txt {
			color: #409EEF;
		}
	}

	.theFlex {
		display: flex;
		flex-direction: row;
		justify-content: space-between
	}

	.flexRow {
		display: flex;
		flex-direction: row;
	}

	.fontHidden1 {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.fontHidden {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}

	.synchronousbtn {
		border-color: #0085DD;
		color: #0085DD;
		background: #ffffff
	}

	.synchronousBGC {
		background: #7FB0D0;
		box-shadow: 0px 0px 3px 2px white inset;
		color: white;
	}

	.tagNameBtn-Box {
		display: none
	}

	/* .tagNameBgc */
	.tagNameBgc {
		background: rgba(64, 158, 255, 0.07);
	}

	.tagNameBgc:hover .tagNameBtn-Box {
		display: block
	}

	.chooseTag {
		color: #409eff;
	}

	.search-box {
		display: flex;
		flex-wrap: wrap;
		flex: 1 1 auto;
	}

	.search-right {
		flex: 0 0 auto;
		margin-top: 10px;
	}

	.tag-item {
		display: flex;
		overflow: hidden;
		height: 40px;
		line-height: 40px;
		font-size: 14px;

		.tag-item-left {
			flex: 1 1 auto;
			display: flex;
			margin-right: 20px;

			.name {
				max-width: 200px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.count {
				color: #666;
				margin-left: 5px;

			}
		}

		.tag-item-right {
			flex: 0 0 auto;
		}
	}
</style>
